import { LoaderFunctionArgs } from '@remix-run/node'
import { json, Link, useLoaderData } from '@remix-run/react'
import { useCallback, useState } from 'react'
import DownloadButtons from '~/components/DownloadButtons'
import useTranslator from '~/lib/useTranslator'

export async function loader({ params }: LoaderFunctionArgs) {
  if (params.args) {
    const url = decodeURIComponent(params.args)
    return json({ url })
  }
  return json({ url: null })
}

export default function Deeplink() {
  const { url } = useLoaderData<typeof loader>()
  const [showInstallInstructions, setShowInstallInstructions] = useState(false)
  const Translator = useTranslator()

  const onOpenHeja = useCallback(() => {
    // Fallback if app isn't installed
    const now = new Date().valueOf()
    setTimeout(function () {
      if (new Date().valueOf() - now > 500) return
      setShowInstallInstructions(true)
    }, 250)

    window.location.href = 'heja:/' + url
  }, [url])

  return (
    <div className="h-screen px-4 w-full max-w-screen-sm mx-auto">
      <div>
        <Link to="https://heja.io" className="block mt-10 mb-5 w-[90px]" prefetch="render">
          <div className="logo">
            <img src="/images/heja-logo-green.svg" width={90} height={47} alt="Heja logo" />
          </div>
        </Link>
        <div>
          <h1 className="text-2xl">{Translator.singular(`That's an app link`)}</h1>
        </div>
        <div>
          <p>
            {Translator.singular(
              'This link was meant to take you to the Heja app. Open Heja below.'
            )}
          </p>
        </div>
      </div>

      <div className="mt-5 pt-5 border-t border-gray-200">
        <div className={`${showInstallInstructions ? 'block' : 'hidden'}`}>
          <h4>{Translator.singular('Unable to open Heja?')}</h4>
          <p className="text-base mb-4">
            {Translator.singular('Make sure Heja is downloaded and installed')}
          </p>
          <DownloadButtons />
          <p className="text-base mt-4">{Translator.singular('Once installed:')}</p>
        </div>
        <button
          type="button"
          className={`bg-black block text-center cursor-pointer hover:bg-black/80 text-white px-3 py-2 rounded-full font-medium shadow-md mt-5 mx-3`}
          onClick={onOpenHeja}
        >
          Open Heja
        </button>
      </div>
    </div>
  )
}
